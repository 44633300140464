exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-corrosion-day-past-tsx": () => import("./../../../src/pages/corrosion-day/past.tsx" /* webpackChunkName: "component---src-pages-corrosion-day-past-tsx" */),
  "component---src-pages-corrosion-day-scsd-2022-tsx": () => import("./../../../src/pages/corrosion-day/scsd_2022.tsx" /* webpackChunkName: "component---src-pages-corrosion-day-scsd-2022-tsx" */),
  "component---src-pages-corrosion-day-scsd-2023-tsx": () => import("./../../../src/pages/corrosion-day/scsd_2023.tsx" /* webpackChunkName: "component---src-pages-corrosion-day-scsd-2023-tsx" */),
  "component---src-pages-corrosion-day-scsd-2024-tsx": () => import("./../../../src/pages/corrosion-day/scsd_2024.tsx" /* webpackChunkName: "component---src-pages-corrosion-day-scsd-2024-tsx" */),
  "component---src-pages-corrosion-day-tsx": () => import("./../../../src/pages/corrosion-day.tsx" /* webpackChunkName: "component---src-pages-corrosion-day-tsx" */),
  "component---src-pages-education-academic-teaching-tsx": () => import("./../../../src/pages/education/academic-teaching.tsx" /* webpackChunkName: "component---src-pages-education-academic-teaching-tsx" */),
  "component---src-pages-education-practitioners-courses-tsx": () => import("./../../../src/pages/education/practitioners-courses.tsx" /* webpackChunkName: "component---src-pages-education-practitioners-courses-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-tsx": () => import("./../../../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */)
}

